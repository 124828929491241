import "./pricing.css"

import party from '../../../assets/images/party.svg';
import wedding from '../../../assets/images/wedding.svg';

export default function Pricing() {
  return <div className="pricing">
    <h2>Ceník</h2>

    <div className="prices-container">
      <div className="price-container">
        <div className="title">
          Fotokoutek na večírek
        </div>
        <img src={party} height={80} alt="party" />
        <div className="description">
          <div>5 hodín fotografování</div>
          <div>Neomezený tisk fotografií 10x15cm</div>
          <div>Online album k prohlížení a stahování fotek</div>
          <div>Výroba grafiky na fotky a pro Fotokoutek</div>
          <div>Fotorekvizity</div>
          <div>Obsluha Fotoboxu na celou dobu</div>
        </div>
        <div className="price">
          7999 kč
        </div>
      </div>

      <div className="price-container">
        <div className="title">
          Fotokoutek na svatbu
        </div>
        <img src={wedding} height={80} alt="party" />

        <div className="description">
          <div>5 hodin fotografování</div>
          <div>Neomezený tisk fotografií 10x15cm</div>
          <div>Online album k prohlížení a stahování fotek</div>
          <div>Výroba grafiky a rámeček na fotky</div>
          <div>Fotorekvizity</div>
          <div>Obsluha Fotoboxu na celou dobu</div>
        </div>
        <div className="price">
          6999 kč
        </div>
      </div>
    </div>


  </div>

};
