import fotokoutek from '../../../assets/images/fotokoutek-side.png';
import "./hero.css"

export default function Hero(params) {
  return <div className='hero'>
    <img src={fotokoutek} id="fotokoutek" alt="logo" className='animate__animated animate__backInLeft' style={{ height: "80%", margin: "5%" }} />
    <div>
      <h1>Nejlepší fotokoutek pro vaši akci</h1>
      <p>Svatby, narozeniny, večírky a víc</p>
      <a href='mailto:contact@smartnest.cz?subject=Fotokoutek'>
        <button className='btn btn-primary'>Poslat žádost</button>
      </a>
    </div>
  </div>
};
