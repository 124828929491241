import Hero from "./components/hero";
import Pricing from "./components/pricing";

function Landing() {
  return (
    <div className="app">
      <Hero />
      <Pricing />
    </div>
  );
}

export default Landing;
